import React from "react"

import { useEffect} from "react"

import Layout from "../components/Layout"

import Seo from "../components/Seo"

export default function Usluga( props) {
	
	useEffect( () => {
		
		let uslugeMenu = null
		
		if( ( uslugeMenu = document.getElementById( "usluge-link")) !== null)
			uslugeMenu.classList.add( "kn-active")
		
	},[])
	
	return (
		<Layout>
			<Seo title={ props.pageContext.title} />
		</Layout>
	)
}
